import React, { Component } from 'react'
import { Link } from "gatsby";

import Header from "../Header/Header"
import Card from "../Card/Card"

import "./Services.css"

import DistributionImg from '../../assets/JarDwellers.png'
import ProdManagementImg from '../../assets/project-dashboard.jpg'
import LocalizationImg from '../../assets/translate.jpg'
import TalentImg from '../../assets/audio-console-2723867_1920.jpg'
 

/**
 *  Services page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Services extends Component {
    constructor(props) {
        super(props)

        this.state = { 
            width: 0, 
            height: 0,
            flexDirection: "",
            imageHeight: "",
            cardPadding: "",
            headerContent: ""
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();

        // Listens for window resize...
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    /**
     * Handle window resize trigger
     */
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });

        // Update contet and styles based on screen width
        if(window.innerWidth >= 768) {
            this.setState({
                flexDirection: "row",
                imageHeight: "inherit",
                cardPadding: "5%",
                headerContent: 
                    <div>
                        <p>
                            Sleigh Group brings the best in international content to audiences around the globe, adding value through the highest quality 
                            cultural translation and localization market. Our dedication to quality and efficiency has seen us handle productions of A-list 
                            movies and television shows for the biggest names in entertainment.
                        </p>
                    </div>
            })
        } else {
            this.setState({
                flexDirection: "column",
                imageHeight: "300px",
                cardPadding: "5% 10%",
                headerContent:
                    <div>
                        <p>
                            Sleigh Group brings the best in international content to audiences around the globe, adding value through the highest quality 
                            cultural translation and localization market. Our dedication to quality and efficiency has seen us handle productions of A-list 
                            movies and television shows for the biggest names in entertainment.
                        </p>
                    </div> 
                    
                
            })
        }
    }

    render() {

        return(
            <div>
                <Header 
                    pageName="Services" 
                    bgColor="linear-gradient(140deg, #d12d4c, #ffaf24) 0% 0% / 300% 300%" 
                    bgImage="audio-1844798_1920.jpg"
                    title="What we do"
                    marginTop="80px"
                    paddingBottom="170px"
                    content={this.state.headerContent}
                />

                <div className="slanted-box"></div>

                <div className="main-container" id="services-container">
                    <div className="main-content">
                        <h1 style={{color: 'rgb(0,0,128)'}}>Our Services</h1>
                        <div className="grid-container">
                            <div id="distribution-card" className="service-card">
                                <Card 
                                    title="Distribution"
                                    content={
                                        <div>
                                            <p>
                                                Sleigh Group distributes content globally, using our connections and expertise to bring it to new markets 
                                                where language and culture may be an initial barrier. If you’re a network looking for relatable global media 
                                                in your language, or a content creator looking for a wider audience, please <a href="mailto:sales@sleigh.group" style={{color: 'rgb(0,0,128)'}}>contact us</a> to discuss your needs.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage={DistributionImg}
                                    direction="column"
                                />
                            </div>

                            <div id="production-management-card" className="service-card">
                                <Card 
                                    title="Production Management"
                                    content={
                                        <div>
                                            <p>
                                                Sleigh Group offers end to end production management services from early development through to 
                                                final delivery. Whether it’s live action or animation, conventional or experimental, we can help 
                                                you find the people you need to complete your creative and technical production team – or run the 
                                                entire production process to create artistic magic with minimal stress. If you have a project you’d 
                                                like to discuss with us, please <a href="mailto:contact@sleigh.group" style={{color: 'rgb(0,0,128)'}}>contact us</a> to set up a meeting.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage={ProdManagementImg}
                                    // imageHeight= {this.state.imageHeight}
                                    direction="column"
                                    // padding= {this.state.cardPadding}
                                />
                            </div>

                            <div id="talent-card" className="service-card">
                                <Card 
                                    title="Talent Development"
                                    content={
                                        <div>
                                            <p>
                                                We invest in our talent providing our writers, directors, actors and voice talent with additional 
                                                skills and support to bring them to the next level in their careers. We provide experience on 
                                                actual commercial projects with mentoring to help creatives advance in their chosen field, 
                                                or branch out into new areas of production.
                                            </p>
                                        </div>
                                    }
                                    backgroundImage={TalentImg}
                                    direction="column"
                                />
                            </div>
                            
                            <div id="translation-card" className="service-card">
                                <Card 
                                    title="Localization"
                                    content={
                                        <div>
                                            <p>
                                                We offer full localization services from subtitling to fully immersive dubbing that makes your content feel as though it 
                                                was filmed in the dubbed language. We confidently offer 11 languages as standard and increasing support for additional 
                                                languages all the time. If you have a project you’d like to talk to us about, please <Link to='/inquire'>tell us a bit more</Link>, 
                                                or drop us an email at 
                                                <a href="mailto:sales@sleigh.group" class="cryptedmail"
                                                data-name="sales"
                                                data-domain="sleigh"
                                                data-tld="group"></a>
                                            </p>
                                           
                                        </div>
                                    }
                                    backgroundImage={LocalizationImg}
                                    direction="column"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}